import { createStore } from "vuex";

interface Filtros {
  Categoria: number[];
  Produto: number[];
  Loja: number[];
  GrupoEmpresarial: number[];
  Regiao: number[];
  Safra: number[];
  MatrizProgramas: number[];
}

export default createStore({
  state: {
    MockLogin: false,
    snack: {
      text: "",
      active: false,
      type: "",
    },

    filtros: {
      Categoria: [],
      Produto: [],
      Loja: [],
      Regiao: [],
      Safra: [],
      GrupoEmpresarial: [],
      MatrizProgramas: [],
    } as Filtros,
    selectedSafra: null,
    userInfo: {},
    isImplanta: false, 
    sessaoExpirada: false,

    filterChange: 1,

    navMini: false,

    isMobile: true,

    menuMobile: false,
    menuDesktop: false,

    redirectRoute: 1,

    darkTheme: false,

    estoqueFisicoDisponivel: null,
    loadingEstoqueFisicoDisponivel: false,

    patternFillsHighCharts: [
      "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
      "M 0 10 L 10 0 M -1 1 L 1 -1 M 9 11 L 11 9",
      "M 3 0 L 3 10 M 8 0 L 8 10",
      "M 0 3 L 10 3 M 0 8 L 10 8",
      "M 0 3 L 5 3 L 5 0 M 5 10 L 5 7 L 10 7",
      "M 3 3 L 8 3 L 8 8 L 3 8 Z",
      "M 5 5 m -4 0 a 4 4 0 1 1 8 0 a 4 4 0 1 1 -8 0",
      "M 10 3 L 5 3 L 5 0 M 5 10 L 5 7 L 0 7",
      "M 2 5 L 5 2 L 8 5 L 5 8 Z",
      "M 0 0 L 5 10 L 10 0",
    ],

    mapMeses: {
      Janeiro: { abreviado: "Jan", completo: "Janeiro", order: 1 },
      janeiro: { abreviado: "Jan", completo: "Janeiro", order: 1 },
      January: { abreviado: "Jan", completo: "Janeiro", order: 1 },
      january: { abreviado: "Jan", completo: "Janeiro", order: 1 },
      jan: { abreviado: "Jan", completo: "Janeiro", order: 1 },
      Jan: { abreviado: "Jan", completo: "Janeiro", order: 1 },
      "1": { abreviado: "Jan", completo: "Janeiro", order: 1 },
      "01": { abreviado: "Jan", completo: "Janeiro", order: 1 },

      Fevereiro: { abreviado: "Fev", completo: "Fevereiro", order: 2 },
      fevereiro: { abreviado: "Fev", completo: "Fevereiro", order: 2 },
      February: { abreviado: "Fev", completo: "Fevereiro", order: 2 },
      february: { abreviado: "Fev", completo: "Fevereiro", order: 2 },
      feb: { abreviado: "Fev", completo: "Fevereiro", order: 2 },
      fev: { abreviado: "Fev", completo: "Fevereiro", order: 2 },
      Feb: { abreviado: "Fev", completo: "Fevereiro", order: 2 },
      Fev: { abreviado: "Fev", completo: "Fevereiro", order: 2 },
      "2": { abreviado: "Fev", completo: "Fevereiro", order: 2 },
      "02": { abreviado: "Fev", completo: "Fevereiro", order: 2 },

      Marco: { abreviado: "Mar", completo: "Março", order: 3 },
      marco: { abreviado: "Mar", completo: "Março", order: 3 },
      Março: { abreviado: "Mar", completo: "Março", order: 3 },
      março: { abreviado: "Mar", completo: "Março", order: 3 },
      March: { abreviado: "Mar", completo: "Março", order: 3 },
      march: { abreviado: "Mar", completo: "Março", order: 3 },
      mar: { abreviado: "Mar", completo: "Março", order: 3 },
      Mar: { abreviado: "Mar", completo: "Março", order: 3 },
      "3": { abreviado: "Mar", completo: "Março", order: 3 },
      "03": { abreviado: "Mar", completo: "Março", order: 3 },

      abril: { abreviado: "Abr", completo: "Abril", order: 4 },
      Abril: { abreviado: "Abr", completo: "Abril", order: 4 },
      April: { abreviado: "Abr", completo: "Abril", order: 4 },
      april: { abreviado: "Abr", completo: "Abril", order: 4 },
      abr: { abreviado: "Abr", completo: "Abril", order: 4 },
      apr: { abreviado: "Abr", completo: "Abril", order: 4 },
      Abr: { abreviado: "Abr", completo: "Abril", order: 4 },
      Apr: { abreviado: "Abr", completo: "Abril", order: 4 },
      "4": { abreviado: "Abr", completo: "Abril", order: 4 },
      "04": { abreviado: "Abr", completo: "Abril", order: 4 },

      Maio: { abreviado: "Mai", completo: "Maio", order: 5 },
      maio: { abreviado: "Mai", completo: "Maio", order: 5 },
      May: { abreviado: "Mai", completo: "Maio", order: 5 },
      may: { abreviado: "Mai", completo: "Maio", order: 5 },
      Mai: { abreviado: "Mai", completo: "Maio", order: 5 },
      mai: { abreviado: "Mai", completo: "Maio", order: 5 },
      "5": { abreviado: "Mai", completo: "Maio", order: 5 },
      "05": { abreviado: "Mai", completo: "Maio", order: 5 },

      Junho: { abreviado: "Jun", completo: "Junho", order: 6 },
      junho: { abreviado: "Jun", completo: "Junho", order: 6 },
      June: { abreviado: "Jun", completo: "Junho", order: 6 },
      june: { abreviado: "Jun", completo: "Junho", order: 6 },
      Jun: { abreviado: "Jun", completo: "Junho", order: 6 },
      jun: { abreviado: "Jun", completo: "Junho", order: 6 },
      "6": { abreviado: "Jun", completo: "Junho", order: 6 },
      "06": { abreviado: "Jun", completo: "Junho", order: 6 },

      Julho: { abreviado: "Jul", completo: "Julho", order: 7 },
      julho: { abreviado: "Jul", completo: "Julho", order: 7 },
      July: { abreviado: "Jul", completo: "Julho", order: 7 },
      july: { abreviado: "Jul", completo: "Julho", order: 7 },
      Jul: { abreviado: "Jul", completo: "Julho", order: 7 },
      jul: { abreviado: "Jul", completo: "Julho", order: 7 },
      "7": { abreviado: "Jul", completo: "Julho", order: 7 },
      "07": { abreviado: "Jul", completo: "Julho", order: 7 },

      Agosto: { abreviado: "Ago", completo: "Agosto", order: 8 },
      agosto: { abreviado: "Ago", completo: "Agosto", order: 8 },
      August: { abreviado: "Ago", completo: "Agosto", order: 8 },
      august: { abreviado: "Ago", completo: "Agosto", order: 8 },
      Aug: { abreviado: "Ago", completo: "Agosto", order: 8 },
      Ago: { abreviado: "Ago", completo: "Agosto", order: 8 },
      aug: { abreviado: "Ago", completo: "Agosto", order: 8 },
      ago: { abreviado: "Ago", completo: "Agosto", order: 8 },
      "8": { abreviado: "Ago", completo: "Agosto", order: 8 },
      "08": { abreviado: "Ago", completo: "Agosto", order: 8 },

      Setembro: { abreviado: "Set", completo: "Setembro", order: 9 },
      setembro: { abreviado: "Set", completo: "Setembro", order: 9 },
      September: { abreviado: "Set", completo: "Setembro", order: 9 },
      september: { abreviado: "Set", completo: "Setembro", order: 9 },
      Sep: { abreviado: "Set", completo: "Setembro", order: 9 },
      Set: { abreviado: "Set", completo: "Setembro", order: 9 },
      sep: { abreviado: "Set", completo: "Setembro", order: 9 },
      set: { abreviado: "Set", completo: "Setembro", order: 9 },
      "9": { abreviado: "Set", completo: "Setembro", order: 9 },
      "09": { abreviado: "Set", completo: "Setembro", order: 9 },

      Outubro: { abreviado: "Out", completo: "Outubro", order: 10 },
      outubro: { abreviado: "Out", completo: "Outubro", order: 10 },
      October: { abreviado: "Out", completo: "Outubro", order: 10 },
      october: { abreviado: "Out", completo: "Outubro", order: 10 },
      Oct: { abreviado: "Out", completo: "Outubro", order: 10 },
      Out: { abreviado: "Out", completo: "Outubro", order: 10 },
      oct: { abreviado: "Out", completo: "Outubro", order: 10 },
      out: { abreviado: "Out", completo: "Outubro", order: 10 },
      "10": { abreviado: "Out", completo: "Outubro", order: 10 },

      Novembro: { abreviado: "Nov", completo: "Novembro", order: 11 },
      novembro: { abreviado: "Nov", completo: "Novembro", order: 11 },
      November: { abreviado: "Nov", completo: "Novembro", order: 11 },
      november: { abreviado: "Nov", completo: "Novembro", order: 11 },
      Nov: { abreviado: "Nov", completo: "Novembro", order: 11 },
      nov: { abreviado: "Nov", completo: "Novembro", order: 11 },
      "11": { abreviado: "Nov", completo: "Novembro", order: 11 },

      Dezembro: { abreviado: "Dez", completo: "Dezembro", order: 12 },
      dezembro: { abreviado: "Dez", completo: "Dezembro", order: 12 },
      December: { abreviado: "Dez", completo: "Dezembro", order: 12 },
      december: { abreviado: "Dez", completo: "Dezembro", order: 12 },
      Dec: { abreviado: "Dez", completo: "Dezembro", order: 12 },
      Dez: { abreviado: "Dez", completo: "Dezembro", order: 12 },
      dec: { abreviado: "Dez", completo: "Dezembro", order: 12 },
      dez: { abreviado: "Dez", completo: "Dezembro", order: 12 },
      "12": { abreviado: "Dez", completo: "Dezembro", order: 12 },
    },
    resizeScreen: 0,
    cidadesAtendidasCards: null,

    dashboardSellOut: {} as any,

    loadingdashboardSellOut: false,
  },

  mutations: {
    defineUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      state.isImplanta = userInfo.email.endsWith('@implantait.com.br');
    },

    defineSnack(state, snack) {
      state.snack = snack;
    },

    defineResizeScreen(state) {
      state.resizeScreen++;
    },

    definedashboardSellOut(state, dashboardSellOut) {
      state.dashboardSellOut = dashboardSellOut;
    },

    defineloadingdashboardSellOut(state, loadingdashboardSellOut) {
      state.loadingdashboardSellOut = loadingdashboardSellOut;
    },

    defineMobile(state, isMobile) {
      state.isMobile = isMobile;
    },

    defineSessaoExpirada(state, sessaoExpirada) {
      state.sessaoExpirada = sessaoExpirada;
    },

    defineCidadesAtendidasCards(state, cidadesAtendidasCards) {
      state.cidadesAtendidasCards = cidadesAtendidasCards;
    },

    addFiltro(
      state,
      payload: {
        nomeFiltro: keyof Filtros;
        code: number;
      },
    ) {
      const { nomeFiltro, code } = payload;
      state.filtros[nomeFiltro].push(code);
    },

    removeFilter(state, payload: { nomeFiltro: keyof Filtros; code: number }) {
      const { nomeFiltro, code } = payload;
      state.filtros[nomeFiltro] = state.filtros[nomeFiltro].filter(
        (c) => c !== code,
      );
    },

    clearFilters(state, nomeFiltro: keyof Filtros) {
      state.filtros[nomeFiltro] = [];
    },

    defineChangeFilter(state, change) {
      state.filterChange += change;
    },

    navMini(state, value) {
      state.navMini = value;
    },

    routeRedirected(state, change) {
      state.redirectRoute += change;
    },

    defineMobileMenu(state, value) {
      state.menuMobile = value;
    },

    defineDesktopMenu(state, value) {
      state.menuDesktop = value;
    },

    defineTheme(state, value) {
      state.darkTheme = value;
    },

    defineEstoqueFisicoDisponivel(state, value) {
      state.estoqueFisicoDisponivel = value;
    },

    defineLoadingEstoqueFisicoDisponivel(state, value) {
      state.loadingEstoqueFisicoDisponivel = value;
    },
  },
});
