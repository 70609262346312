
import { defineComponent } from "vue";

import * as FT from "../../../interfaces/filtrosGlobais";

import BoxFilter from "./BoxFilter.vue";

export default defineComponent({
  components: {
    BoxFilter,
  },
  data() {
    return {
      filtros: [
        {
          name: "Categorias",
          endpoint: "getCategorias",
          descricao: "descricaoCategoria",
          cod: "codigoCategoria",

          filterName: "Categoria",
        },

        {
          name: "Produtos",
          endpoint: "getProdutos",
          descricao: "descricaoProduto",
          cod: "codigoProduto",

          filterName: "Produto",
        },

        {
          name: "Lojas",
          endpoint: "getLojas",
          descricao: "nomeRevenda",
          cod: "codigoRevenda",

          filterName: "Loja",
        },

        {
          name: "Grupo Empresarial",
          endpoint: "getGrupos",
          descricao: "nomeGrupo",
          cod: "codigoGrupo",

          filterName: "GrupoEmpresarial",
          admin: true,
        },

        {
          name: "Regiões",
          endpoint: "getRegioes",
          descricao: "descricaoRegiao",
          cod: "codigoRegiao",

          filterName: "Regiao",
        },
        {
          name: "Safras",
          endpoint: "getSafras",
          descricao: "periodo",
          cod: "codigoSafra",

          filterName: "Safra",
        },
        {
          name: "Matriz de Programas",
          endpoint: "getMatrizPrograma",
          descricao: "valorCampo",
          cod: "codigoRevenda",

          filterName: "MatrizProgramas",
        },
      ] as FT.filtro[],
    };
  },

  methods: {
    cancelar() {
      this.$emit("cancelar");
    },
    aplicar() {
      this.$emit("aplicar");
    },
  },
});
