import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },

  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () => import("../views/NotFoundView.vue"),
  },

  {
    path: "/administracao",
    name: "administracao",
    redirect: { name: "adesao" },
    meta: {
      title: "Administração",
      vicon: "mdi-cog",
      requiresAuth: true,
      adminOnly: true,
    },

    children: [
      {
        path: "/adesao",
        name: "adesao",
        component: () => import("../views/AdesaoView.vue"),
        meta: {
          title: "Adesão",
          requiresAuth: true,
          adminOnly: true,
        },
      },
      {
        path: "/auditoria",
        name: "auditoria",
        component: () => import("../views/AuditoriaView.vue"),
        meta: {
          title: "Auditoria",
          requiresAuth: true,
          adminOnly: true,
        },
      },
      {
        path: "/tipoDocumento",
        name: "tipoDocumento",
        component: () => import("../views/TipoDocumentoView.vue"),
        meta: {
          title: "Tipo de Documento",
          requiresAuth: true,
          adminOnly: true,
        },
      },
      {
        path: "/canal",
        name: "canal",
        component: () => import("../views/CanalView.vue"),
        meta: {
          title: "Canal",
          requiresAuth: true,
          adminOnly: true,
        },
      },
    ],
  },

  {
    path: "/",
    name: "home",
    redirect: { name: "selloutAgricultores" },

    meta: {
      title: "SellOut",
      vicon: "mdi-cart-arrow-up",
      requiresAuth: true,
      adminOnly: false,
    },

    children: [
      {
        path: "/sellout-agricultores",
        name: "selloutAgricultores",
        component: () => import("../views/SelloutView.vue"),
        meta: {
          title: "Visão Geral",
          requiresAuth: true,
          adminOnly: false,
        },
      },
      {
        path: "/agrega-participantes",
        name: "agregaParticipantes",
        component: () => import("../views/AgregaView.vue"),
        meta: {
          title: "Agrega",
          requiresAuth: true,
          adminOnly: false,
        },
      },
      {
        path: "/produto-totalizadores",
        name: "produtoTotlizadores",
        component: () => import("../views/ProdutoView.vue"),
        meta: {
          title: "Produto",
          requiresAuth: true,
          adminOnly: false,
        },
      },
      {
        path: "/extracao",
        name: "extracao",
        component: () => import("../views/ExtracaoView.vue"),
        meta: {
          title: "Última extração",
          requiresAuth: true,
          adminOnly: false,
          visible: false,
        },
      },
    ],
  },

  {
    path: "/estoque",
    name: "estoque",
    redirect: { name: "estoqueMes" },

    meta: {
      title: "Gestão de estoque",
      vicon: "mdi-cube-outline",
      requiresAuth: true,
      adminOnly: false,
    },

    children: [
      {
        path: "/estoque-mes",
        name: "estoqueMes",
        component: () => import("../views/EstoqueView.vue"),
        meta: {
          title: "Estoque Mês",
          requiresAuth: true,
          adminOnly: false,
        },
      },
    ],
  },

  {
    path: "/vendas",
    name: "vendas",
    redirect: { name: "vendasGeolocalizacao" },

    meta: {
      title: "Vendas",
      vicon: "mdi-chart-timeline-variant",
      requiresAuth: true,
      adminOnly: true,
    },

    children: [
      {
        path: "/vendas-por-geolocalizacao",
        name: "vendasGeolocalizacao",
        component: () => import("../views/VendasView.vue"),
        meta: {
          title: "Vendas por Geolocalização",
          requiresAuth: true,
          adminOnly: true,
        },
      },

      {
        path: "/cidades-atendidas",
        name: "cidadesAtendidas",
        component: () => import("../views/CidadesAtendidasView.vue"),
        meta: {
          title: "Cidades Atendidas",
          requiresAuth: true,
          adminOnly: true,
        },
      },

      {
        path: "/graficos-cidades",
        name: "graficosCidades",
        component: () => import("../views/GraficoCidadesView.vue"),
        meta: {
          title: "Gráfico Cidades",
          requiresAuth: true,
          adminOnly: true,
        },
      },
    ],
  },

  {
    path: "/trilha-aprendizagem",
    name: "trilha-aprendizagem",
    component: () => import("../views/TrilhaAprendizagem.vue"),
  },

  {
    path: "/administracao-implanta",
    name: "administracao-implanta",
    redirect: { name: "usuarios" },
    meta: {
      title: "Administração Implanta",
      vicon: "mdi-cog",
      requiresAuth: true,
      adminOnly: true,
      implantaOnly: true
    },

      children: [
        {
          path: "/usuarios",
          name: "usuarios",
          component: () => import("../views/UsuariosView.vue"),
          meta: {
            title: "Usuarios",
            requiresAuth: true,
            adminOnly: true,
            implantaOnly: true
          },
        }
      ]
    }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = document.cookie
    .split("; ")
    .find((cookie) => cookie.startsWith("session="));

  const logged = !!token;

  if (to.matched.some((record) => record.meta.requiresAuth) && !logged) {
    next("/login");

    return;
  }

  if (to.name === "login" && logged) {
    next("/");

    return;
  }
  next();
});

export default router;
