
import { defineComponent } from "vue";

export default defineComponent({
  data: () => {
    return {
      showMenu: false,
      user: {} as any,

      trocarSenha: false,

      hideSenhaAtual: true,
      hideNovaSenha: true,
      hideConfirmarSenha: true,

      salvarSenhaLoading: false,

      senhaAtual: "",
      novaSenha: "",
      confirmarNovaSenha: "",
      sucessoSalvar: false,
      erroSalvar: false,

      passwordRules: [
        (v: string) => !!v || "Campo obrigatório",
        (v: string) =>
          (v && v.length >= 6) || "Senha deve ter pelo menos 6 caracteres.",
        (v: string) =>
          /[a-z]/i.test(v) || "Senha deve conter pelo menos uma letra",
        (v: string) => /\d/.test(v) || "Senha deve conter pelo menos um número",
        (v: string) =>
          /[-!$%^&*()_+|~=`{}[\]:";'@<>?,./]/.test(v) ||
          "Senha deve conter pelo menos um caractere especial (!$%^&*()_+@...)",
      ],

      passOldRule: [(v: string) => !!v || "Campo obrigatório"],
    };
  },
  computed: {
    errorSenhasDiferentes() {
      return this.novaSenha !== this.confirmarNovaSenha;
    },

    faltaCampo() {
      return !(this.senhaAtual && this.novaSenha && this.confirmarNovaSenha);
    },

    erroRegraSenha() {
      let rule1 = !this.novaSenha;
      let rule2 = !(this.novaSenha.length >= 6);
      let rule3 = !/[a-z]/i.test(this.novaSenha);
      let rule4 = !/\d/.test(this.novaSenha);
      let rule5 = !/[-!$%^&*()_+|~=`{}[\]:";'@<>?,./]/.test(this.novaSenha);

      return rule1 || rule2 || rule3 || rule4 || rule5;
    },

    initials(): string {
      const fullName: string = this.$store.state.userInfo.nome || "";
      const names = fullName
        .split(" ")
        .filter((name) => !["da", "de", "do"].includes(name));
      let initials = "";

      if (names.length > 0) {
        initials += names[0][0];
      }

      if (names.length > 1) {
        initials += names[1][0];
      }
      return initials.toUpperCase();
    },
  },
  beforeMount() {
    const userValue = this.$store.state.userInfo ?? {};

    this.user = userValue;
  },
  mounted() {
    this.showMenu = true;
  },
  methods: {
    closeMenu() {
      this.showMenu = false;
      setTimeout(() => {
        this.$store.commit("defineDesktopMenu", false);
      }, 100);
    },
    downloadRelatorioUsuario() {
      this.$axios
        .get("/Usuarios/relatorioLogin", { responseType: "blob" })
        .then((res) => {
          const link = document.createElement("a");

          link.href = window.URL.createObjectURL(new Blob([res.data]));

          link.setAttribute("download", "report.xlsx");

          document.body.appendChild(link);

          link.click();
        })
        .catch((error) => {
          // Trate erros aqui
          console.error(error);
        });
    },

    salvarSenha() {
      let params = {
        email: this.user.email,
        senhaatual: this.senhaAtual,
        novasenha: this.novaSenha,
      };
      this.salvarSenhaLoading = true;
      this.$axios
        .post("/Usuarios/UpdatePassword", params)
        .then(() => {
          this.trocarSenha = false;

          this.sucessoSalvar = true;
          setTimeout(() => {
            this.sucessoSalvar = false;
          }, 5000);
        })
        .catch(() => {
          this.erroSalvar = true;
          setTimeout(() => {
            this.erroSalvar = false;
          }, 10000);
        })
        .finally(() => {
          this.salvarSenhaLoading = false;
        });
    },
  },
});
